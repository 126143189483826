import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const unefeuille = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Une feuille" />
    <h3 className='underline-title'>Une feuille</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Une feuille parmi des feuilles<br />
        Elle danse la rumba langoureuse<br />
        Au son cajolant de la brise berceuse<br />
        Elle danse le jerk au rythme fracassant<br />
        Au son endiablé de l'ouragan<br />
        Une feuille parmi des feuilles<br />
      </p>
      <p>
        Une chèvre au gré du vent<br />
        Passe près d'une feuille<br />
        Son mufle reniflant<br />
        Bouge une feuille<br />
        Sa gueule boutarde<br />
        Arrache une feuille:<br />
        Une feuille qui suit une feuille<br />
        Une feuille allait être broutée<br />
        Une feuille qui suit une feuille<br />
        A été rapidement broutée<br />
        Une feuille parmi des feuilles<br />
        N'est heureusement pas broutée<br />
      </p>
      <p>
        Une chèvre passe <br />
        Une feuille danse <br />
        Une chèvre broute une feuille<br />
        Une feuille a failli être broutée<br />
        Une feuille parmi des feuilles broutées<br />
        N'est finalement pas broutée<br />
      </p>
      <p>
        Réalisez la peur kafkaïenne<br />
        D'une feuille parmi des feuilles!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default unefeuille
